@import "~bootstrap/scss/bootstrap";
@import "font-sizes";
@import "colors";

body{
  font-family: 'Open Sans', sans-serif;
}

.page-container > header{
  min-height: 145px;
  padding-top: 10px;
  background-color: $main-theme-color;
}

.logo-container{
  padding-top: 10px;
  img{
    width: 100px;
    height: 100px;
  }
}

.login-link{
  font-size: $large-link-size;
  color: $foreground-color;
  &:hover{
    color: $foreground-color;
    text-decoration: underline;
  }
}

.homepage{
  .greeting-text{
    color: $main-theme-color;
  }
  .description {
    img{
      margin-top: 20px;
      width: 100%;
    }
  }
}